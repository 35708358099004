<div class="box box1">
    <div class="buttons">
        <textarea name="note" class="note back-light-blue-color" placeholder="{{'PRIVATE.PATIENT.ADDITIONAL-NOTES.TEXTAREA-PLACEHOLDER' | translate}}" [(ngModel)]="newNote.note"></textarea>
        <button class="button tipo-white-color back-blue-color" (click)="generateNewNote()" [ngClass]="{'back-blue-color': newNote.note !== '', 'inactive': newNote.note === ''}">
            <fa-icon [icon]="faPlus" class="icono"></fa-icon>
          {{"PRIVATE.PATIENT.ADDITIONAL-NOTES.BUTTON-NEW-NOTE" | translate}}
        </button>
    </div>
   
    <div class="notes-title subtitle tipo-second-color bold">
      <p> {{"PRIVATE.PATIENT.ADDITIONAL-NOTES.TITLE-NOTES" | translate}} </p>
      <button class="button tipo-white-color back-blue-color" (click)="exportAllNotes()">
        <fa-icon [icon]="faDownload" class="icono"></fa-icon>
          {{"PRIVATE.PATIENT.ADDITIONAL-NOTES.EXPORT-NOTES" | translate}}
      </button>
    </div>
    <div class="beforenotes" *ngFor="let note of notes">
      <p class="regular beforenote back-light-blue-color" [innerHTML]="formatNote(note.note)"></p>
      <div class="datanote">
        <p class="regular tipo-second-color bold small">
          <fa-icon [icon]="faUser" class="icono"></fa-icon> {{note.createdBy}}
        </p>
        <p class="regular small">
          <fa-icon [icon]="faCalendar" class="icono"></fa-icon> {{note.createdOn}}
        </p>
      </div>
    </div>
</div>
