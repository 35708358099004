import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ElciValidators {

  static notEqualToZero(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value !== '0') {
      return null; // It's valid
    } else {
      return { notEqualToZero: true }; // It's invalid
    }
  }

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value || value === ""){
      return null;
    }else{
      const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      return !isValidEmail ? { 'invalidEmail': true } : null;
    }
  }

  static trueOrFalseInteger(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value !== 0 || value !== 1) {
      return null; // It's valid
    } else {
      return { trueOrFalseInteger: true }; // It's invalid
    }
  }

  static noPastDate(control: AbstractControl): ValidationErrors | null {
    const inputDate = new Date(control.value);
    const today = new Date();
    inputDate.setUTCHours(0, 0, 0, 0);
    today.setUTCHours(0, 0, 0, 0);    
    if (inputDate < today) {
      return { noPastDate: true };
    }
    return null;
  }

}

export const validNumbersValidator = (validValues: number[]) => {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!validValues.includes(value)) {
      return { validNumbersValidator: true }; // It's invalid
    } else {
      return null; // It's valid
    }
  }
}