import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  COMPANY_ROLES,
  DOCTOR_ROLES,
  GENERATE_CIS,
  OPEN_CIS,
  VIEW_CIS
} from '@app/core/constants/Permissions';
import { PatientParamsRouteEnum } from '@app/core/enums/route.enum';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import {
  faBackward,
  faCheckDouble,
  faDownload,
  faFileCirclePlus,
  faFileContract,
  faHospitalUser,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-file-patient-quick',
  templateUrl: './file-patient-quick.component.html',
  styleUrls: ['./file-patient-quick.component.scss']
})
export class FilePatientQuickComponent implements OnInit {
  faFileCirclePlus = faFileCirclePlus;
  faFileContract = faFileContract;
  faTrashCan = faTrashCan;
  faBackward = faBackward;
  faDownload = faDownload;
  faCheckDouble = faCheckDouble;
  faHospitalUser = faHospitalUser;

  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;
  OPEN_CIS = OPEN_CIS;
  VIEW_CIS = VIEW_CIS;
  GENERATE_CIS = GENERATE_CIS;

  patientUuid?: string;
  selectedTab?: string;
  user?: User;
  subscriptions?: SubscriptionTypes[] = [];  
  isBiometric = false;
  confirmDelete = false;
  
  patientFullName?: string; 
  PatientParamsRouteEnum = PatientParamsRouteEnum;

  @Input() isAdmin = false;
  @Input() showRGPDspermission = true;
  @Input() showDocumentsTypes = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly loginService: LoginService
  ) {
    this.activatedRoute.params.subscribe(params => {      
      this.patientUuid = params['uuid'];
      this.selectedTab = params['tab'];
    });
  }

 
  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User(); 
    // Comprobar el tipo de suscripción y si es solo papel, no mostrar botón Biométrica
    const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];    
    this.isBiometric = !!subscriptionType.find(subscription => subscription.id === SubscriptionTypeEnum.BIOMETRIC);     
  }
 
  handleChildPatientFullName(patientFullName: string) {
    this.patientFullName = patientFullName    
  }

  changeTab(tab: string) {
    // Select the tab
    this.selectedTab = tab;
    // Update the router
    this.router.navigate(['../', tab], {
      relativeTo: this.activatedRoute,
    });
  }

 
}
