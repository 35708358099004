<div class="wrapper_admin">  
    <h4 class="title tipo-second-color bold">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.TITLE-FILE-COMPANY" | translate}} </h4>  
        <app-header-company [companyData]="companyData"></app-header-company>
        <app-file-company [his] ="his"></app-file-company>
        
        <app-data-company-admin [companyData]="companyData" 
        [companyDataVidSigner]="companyDataVidSigner" 
        [representativeCompany]="representativeCompany" 
        [totalUsers]="totalUsers"
        [totalCompanies]="totalCompanies"
        [inEdit]="false" 
        [isFieldsDisabled]="true"></app-data-company-admin>
    
</div>
