import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientAdditionalNoteInputDTO, PatientAdditionalNoteOutputDTO } from '@app/core/models/input/patient/patient-additional-notes.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdditionalNotesService {

  constructor(
    private readonly http: HttpClient, 
    private readonly env: EnvironmentService
  ) { }

  getPatientAdditionalNotes(patientUuid: string): Observable<PatientAdditionalNoteInputDTO[]> {
    return this.http.get<PatientAdditionalNoteInputDTO[]>(`${this.env.apiUrl}/patients/${patientUuid}/additional-notes`);
  }

  newPatientAdditionalNote(patientUuid: string, additionalNote: PatientAdditionalNoteOutputDTO): Observable<PatientAdditionalNoteInputDTO> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })
  ;
    return this.http.post<PatientAdditionalNoteInputDTO>(`${this.env.apiUrl}/patients/${patientUuid}/additional-notes`, JSON.stringify(additionalNote), {headers: headers})
  }
}
