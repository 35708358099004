import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faPenToSquare, faTrashCan, faEye, faFileExport} from '@fortawesome/free-solid-svg-icons';
import { CompanyAgreement, CompanyDataAdmin, CompanyVidSignerCredential, RepresentativeCompany } from '@app/core/models/input/company/company.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { DoctorsAmounts, PaymentMethodTypes } from '@app/core/models/input/company/company-parameters.model';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import PaymentMethodTypeEnum from '@app/core/enums/payment-method-type.enum';



@Component({
  selector: 'app-data-company-admin',
  templateUrl: './data-company-admin.component.html',
  styleUrls: ['./data-company-admin.component.scss']
})
export class DataCompanyAdminComponent implements OnInit, OnChanges{
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;
  faEye = faEye;
  faFileExport = faFileExport;

    // Inputs
    @Input() inEdit = false;  
    @Input() isFieldsDisabled = true; 
    @Input() companyData?: CompanyDataAdmin;
    @Input() companyDataVidSigner?:CompanyVidSignerCredential;
    @Input() representativeCompany?:RepresentativeCompany;
    @Input() totalUsers?: number;
    @Input() totalCompanies?: number;

  dataCompanyAdmin!: FormGroup;  
  isSubmit = false;
  selectValue = null;
  selectValueFis = null;
  doctorsAmounts?: DoctorsAmounts[];
  companyUuid? :string
  doctorsAmountId?: number;
  paymentMethodTypes?: PaymentMethodTypes[];
  classInput = '';
  subscriptionTypesId: number[] = [];
  paperAccess?:boolean;
  digitalAccess?: boolean;
  remoteSigns?:boolean; 
  showBankAccount = false;
 
   // Html used variables
   subscriptionTypeEnum = SubscriptionTypeEnum;
   paymentMethodTypeEnum = PaymentMethodTypeEnum;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService, 
    private alertService: AlertService,
    private translate: TranslateService, 
    private activatedRoute: ActivatedRoute, 
    )  { 
       // Validamos e inicializamos formulario
      this.initForm();
      this.activatedRoute.params.subscribe(params => {      
        this.companyUuid = params['companyUuid'];      
      });
    }

      // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataCompanyAdmin.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }


  ngOnInit(): void {
    this.getParametersToCreate(); 
    this.toggleForm();    
   } 
  
  private initForm() {
    this.dataCompanyAdmin = new FormGroup({
      doctorsAmountId: new FormControl({ value: '', disabled: false }, [Validators.required]),
      paymentMethodType: new FormControl({ value: '', disabled: false }, [Validators.required]),
      dateOn: new FormControl({ value: '', disabled: false }, []),
      dateOff: new FormControl({ value: '', disabled: false }, []),     
      emailLOPD: new FormControl({ value: '', disabled: false }, []),
      website: new FormControl({ value: '', disabled: false }, []),
      bankAccount: new FormControl({ value: '', disabled: false }, []),
      apiAccess: new FormControl({ value: false, disabled: false }, []),
      paperAccess: new FormControl({ value: '', disabled: false }, []),
      digitalAccess: new FormControl({ value: '', disabled: false }, []),
      remoteSigns: new FormControl({ value: '', disabled: false }, []),
      logoMain: new FormControl({ value: '', disabled: false }, []),
      his: new FormControl({ value: '', disabled: false }, []),
    });
  }

   ngOnChanges(changes: SimpleChanges) { 
    this.companyData = changes['companyData']?.currentValue;    
    this.dataCompanyAdmin.controls['doctorsAmountId'].setValue(this.companyData?.companyAdditionalData?.doctorsAmount?.id);
    this.dataCompanyAdmin.controls['paymentMethodType'].setValue(this.companyData?.companyAdditionalData?.paymentMethodType?.id);
    if(this.companyData?.companyAdditionalData?.paymentMethodType?.id === PaymentMethodTypeEnum.SEPA) {
      this.showBankAccount = true;
    }   
    this.dataCompanyAdmin.controls['dateOn'].setValue(this.companyData?.creationDate);
    this.dataCompanyAdmin.controls['emailLOPD'].setValue(this.companyData?.emailLOPD);
    this.dataCompanyAdmin.controls['website'].setValue(this.companyData?.website);
    this.dataCompanyAdmin.controls['his'].setValue(this.companyData?.companyAdditionalData?.his);
    this.dataCompanyAdmin.controls['bankAccount'].setValue(this.companyData?.companyAdditionalData?.bankAccount);
    this.dataCompanyAdmin.controls['dateOff'].setValue(this.companyData?.cancelDate);
    this.dataCompanyAdmin.controls['paperAccess'].setValue(this.companyData?.subscriptionTypes?.find(subscriptionTypes => subscriptionTypes.id === SubscriptionTypeEnum.PAPER));
    this.dataCompanyAdmin.controls['digitalAccess'].setValue(this.companyData?.subscriptionTypes?.find(subscriptionTypes => subscriptionTypes.id === SubscriptionTypeEnum.BIOMETRIC));
    this.dataCompanyAdmin.controls['apiAccess'].setValue(this.companyData?.apiActive);
    this.dataCompanyAdmin.controls['remoteSigns'].setValue(this.companyData?.subscriptionTypes?.find(subscriptionTypes => subscriptionTypes.id === SubscriptionTypeEnum.REMOTE));   
    this.companyData?.subscriptionTypes?.forEach(subscription => {
      if(subscription.id !== undefined) 
        this.subscriptionTypesId.push(subscription.id)
    })    
  }

  checkPaymentMethod(){   
    if(this.dataCompanyAdmin.get('paymentMethodType')?.value == PaymentMethodTypeEnum.SEPA) {
      this.showBankAccount = true;      
    }else{
      this.showBankAccount = false;
    }
  }
    
  getParametersToCreate(){
    this.companyService.getParametersToCreate().subscribe({
      next: data => {  
        this.companyData = data;  
        this.doctorsAmounts = data['doctorsAmounts'];   
        this.paymentMethodTypes = data['paymentMethodTypes'];  
        
      } 
    })
  } 

  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled;
    this.toggleForm();
    this.dataCompanyAdmin.controls['dateOn'].disable(); 
    this.dataCompanyAdmin.controls['dateOff'].disable();
    this.dataCompanyAdmin.controls['paperAccess'].disable();
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataCompanyAdmin.disable();
      this.inEdit = false;
    } else {
      this.dataCompanyAdmin.enable();
      this.inEdit = true;
    }
  }

 addSubscriptionTypesId(type:number,  isChecked:boolean | undefined){
    if(isChecked){ 
      if (! this.subscriptionTypesId.includes(type)) {
         this.subscriptionTypesId.push(type);
      }   
    }else {
      // Si el checkbox está desmarcado, eliminar el tipo si está presente
      const index =  this.subscriptionTypesId.indexOf(type);
      if (index !== -1) {
         this.subscriptionTypesId.splice(index, 1);
      }   
    }    
    
}

  onSubmit() {

      // marca el input con error si no se rellena bien
      Object.keys(this.dataCompanyAdmin.controls).forEach(controlName => {
        this.dataCompanyAdmin.get(controlName)?.markAsTouched();
      });
      
      if (this.dataCompanyAdmin.valid) {    
        const companyObject: CompanyAgreement = {          
          doctorsAmountId: this.dataCompanyAdmin.get('doctorsAmountId')?.value,
          paymentMethodTypeId:  this.dataCompanyAdmin.controls['paymentMethodType']?.value,         
          emailLOPD: this.dataCompanyAdmin.get('emailLOPD')?.value,
          website: this.dataCompanyAdmin.get('website')?.value,
          his: this.dataCompanyAdmin.get('his')?.value,
          bankAccount: this.dataCompanyAdmin.get('bankAccount')?.value,
          apiActive: !!this.dataCompanyAdmin.get('apiAccess')?.value,         
          subscriptionTypesId:this.subscriptionTypesId        
        }      
        this.companyService.editCompanyAgreement(this.companyUuid ?? '', companyObject).subscribe(() => {
          this.alertService.success(this.translate.instant("PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-MODIFY-ADMINISTRATOR"));
         
        })
        this.changeEdit();
      }
      else {
        this.classInput = 'wrong';
        this.alertService.error(this.translate.instant("PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-ERROR"));
      }
  
    }
  }
