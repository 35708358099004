import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XlsxExporterService } from '@app/core/services/xlsx-exporter/xlsx-exporter.service';
import { faCalendar, faDownload, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { PatientAdditionalNoteInputDTO, PatientAdditionalNoteOutputDTO } from '../../../../core/models/input/patient/patient-additional-notes.model';
import { AdditionalNotesService } from '../../../../core/services/additional-notes/additional-notes.service';

@Component({
  selector: 'app-additional-notes',
  templateUrl: './additional-notes.component.html',
  styleUrls: ['./additional-notes.component.scss']
})
export class AdditionalNotesComponent implements OnInit {

  faUser = faUser;
  faCalendar = faCalendar;
  faPlus = faPlus;
  faDownload = faDownload;

  uuid!: string;
  notes?: PatientAdditionalNoteInputDTO[]
  newNote = new PatientAdditionalNoteOutputDTO() 

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly additionalNotesService: AdditionalNotesService,
    private readonly xlsxExporterService: XlsxExporterService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.uuid = param['uuid'];
    });
  }

  ngOnInit(): void {

    this.getPatientAdditionalNotes();
  }
  
  getPatientAdditionalNotes() {
    if (this.uuid && this.uuid !== '') {
      this.additionalNotesService.getPatientAdditionalNotes(this.uuid).subscribe({
        next: notes => {
          this.notes = notes;
        },
      });
    }
  }

  formatNote(note: string | undefined): string {
    if (!note) {
      return '';
    }
    return note.replace(/\n/g, '<br>');
  }

  generateNewNote(note = this.newNote) {
    if (note && note.note !== "") 
      this.postPatientAdditionalNote(note);
    this.newNote.note = "";
  }

  exportAllNotes() {
    if (this.notes) {
      const now = new Date();
      const formattedDate = now.toISOString().replace('T', '_').replace(/:/g, '-').split('.')[0];
      const fileName = `anotaciones-exportadas_${formattedDate}`;
      this.xlsxExporterService.exportToExcel(this.notes, fileName);
    }
  }

  postPatientAdditionalNote(note: PatientAdditionalNoteOutputDTO) {
    if (this.uuid && this.uuid !== '') { 
      this.additionalNotesService.newPatientAdditionalNote(this.uuid, note).subscribe(() => {
        this.getPatientAdditionalNotes();
      });
    }
  }



}
