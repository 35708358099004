import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import RoleEnum from '@app/core/enums/role.enum';
import { UserOutput } from '@app/core/models/output/user-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { UserService } from '@app/core/services/user/user.service';
import {
  faCircleQuestion,
  faFileExport,
  faPenToSquare,
  faEye,
  faEyeSlash,
  faCheckDouble
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ChangePassword } from '@app/core/models/input/change-pass.model';
import { ADMINISTRATORS_ROLES } from '@app/core/constants/Permissions';
import { ElciValidators } from '@app/core/utils/validators';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import { UserInput } from '@app/core/models/input/user-input.model';
import { LoginService } from '@app/core/services/login/login.service';
import { User } from '@app/core/models/input/user.model';
import { Role } from '@app/core/models/input/role.model';

@Component({
  selector: 'app-data-administrator',
  templateUrl: './data-administrator.component.html',
  styleUrls: ['./data-administrator.component.scss'],
})
export class DataAdministratorComponent implements OnInit {
  // fontawesome
  faPenToSquare = faPenToSquare;
  faFileExport = faFileExport;
  faCircleQuestion = faCircleQuestion;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCheckDouble =  faCheckDouble;

  // Roles
  ADMINISTRATORS_ROLES = ADMINISTRATORS_ROLES;

  // Inputs
  @Input()
  inEdit?: boolean;

  @Input()
  isFieldsDisabled = false;

  @Output() userData = new EventEmitter<UserInput>();

  inUser = false;

  dataAdministrator!: FormGroup;
  dataPass!: FormGroup;
  selectValue = null;
  adminUuid?: string;
  isCheckedViewcis = false;
  isCheckedSupervisor = false;
  isSubmit = false;
  classInput = '';
  roles: RoleEnum[] = [RoleEnum.ADMINISTRATIVE];
  inEdition = true;
  isOpenWindow = false;
  isNewPass = true;
  showPasswordOld = false;
  showPasswordNew = false;
  isCheckedStatus = true;
  confirmInactive = false;
  rolesIds: Set<number> = new Set();
  user?: User;
  isSudo = false;

  registerDate!: string | undefined;
  lastAccessDate!: string | undefined;
  cancelDate!: string | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    private translate: TranslateService,
    private userService: UserService,
    private loginService: LoginService,
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.adminUuid = param['uuid'];
    });
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataAdministrator.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
    // Comprobar el tipo de role del usuario logueado y si es sudo enviamos 0 para que nos devuelva las provincias de los dos países
    const roleType: Role[] = this.user.roles ?? [];
    this.isSudo = !!roleType.find(roles => roles.id === RoleEnum.ADMIN);

    // Validamos e inicializamos formulario
    this.dataAdministrator = new FormGroup({
      name: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      surnametwo: new FormControl(),

      phone: new FormControl({ value: '', disabled: false }, [
        Validators.pattern(/^\d{9,15}$/),
      ]),

      email: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
        ElciValidators.emailValidator,
      ]),

      user: new FormControl({ value: '', disabled: !this.inUser }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      pass: new FormControl({ value: '', disabled: !this.inUser }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      viewcis: new FormControl(),
      supervisor: new FormControl(),
      dateregister: new FormControl({ value: '', disabled: false }),
      datelastaccess: new FormControl({ value: '', disabled: false }),
      canceldate: new FormControl({ value: '', disabled: false }),
      status: new FormControl(),
    });
    this.toggleForm();

    this.dataPass = new FormGroup({
      oldPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      newPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    });   

    if (this.adminUuid !== undefined) {
      this.inUser = false;

      this.dataAdministrator.controls['user'].disable();
      this.userService.getUserByUuid(this.adminUuid).subscribe({
        next: data => {
          this.userData.emit(data);
          const labelCheck = document.querySelector('.switch-button-label');
          if (data.isActive && UserStatusEnum.ACTIVO === data.userStatus?.id) {
            labelCheck?.classList.add('active');
            this.isCheckedStatus = true;
          } else {
            labelCheck?.classList.remove('active');
            this.isCheckedStatus = false;
          }

          // pasar la hora del sistema a la hora local
          this.registerDate = data.creationDate?.toLocaleString();
          this.lastAccessDate = data.lastAccessDate?.toLocaleString();
          this.cancelDate = data.cancelDate?.toLocaleString();

          this.dataAdministrator.controls['name'].setValue(data.name);
          this.dataAdministrator.controls['surname'].setValue(data.firstLastname);
          this.dataAdministrator.controls['surnametwo'].setValue(data.secondLastname);
          this.dataAdministrator.controls['phone'].setValue(data.mobile);
          this.dataAdministrator.controls['email'].setValue(data.email);
          this.dataAdministrator.controls['user'].setValue(data.username);
          this.dataAdministrator.controls['pass'].setValue('****');
         

          if (
            data.roles?.some(
              role => role.id === RoleEnum.ADMINISTRATIVE_VIEW_CIS
            )
          ) {
            this.isCheckedViewcis = true;
          }

          if (
            data.roles?.some(
              role => role.id === RoleEnum.ADMINISTRATIVE_SUPERVISOR
            )
          ) {
            this.isCheckedSupervisor = true;
          }         
          data?.roles?.forEach(role => role.id && this.rolesIds.add(role.id));
        },
      });
    } else {
      this.inUser = true;
    }
  }

  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled;
    this.toggleForm();
    this.isNewPass = !this.isNewPass;
    if (this.adminUuid !== undefined) {
      this.inUser = false;
      this.dataAdministrator.controls['user'].disable();
      this.dataAdministrator.controls['pass'].disable();
    
    }
  }

  checkedStatus() {
    if (this.inEdition) {
      const labelcheck = document.querySelector('.switch-button-label');
      if (this.isCheckedStatus) {
        labelcheck?.classList.remove('active');
        this.isCheckedStatus = false;
        this.confirmInactive = true;
      }else if(this.isSudo) {
        labelcheck?.classList.add('active');
        this.isCheckedStatus = true;
        this.confirmInactive = false;        
      }
    }
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataAdministrator.disable();
      this.inEdition = false;
    } else {
      this.dataAdministrator.enable();
      this.inEdition = true;
    }
  }

  checkedViewcis() {
    if (this.inEdition){
    this.isCheckedViewcis = !this.isCheckedViewcis;
    }
  }
  checkedSupervisor() {
    if (this.inEdition){
    this.isCheckedSupervisor = !this.isCheckedSupervisor;
    }
  }

  // cambiar password
  openWindow() {
    this.isOpenWindow = !this.isOpenWindow;
  }

  changePass() {
    const changePass: ChangePassword = {
      oldPassword: this.dataPass.get('oldPassword')?.value,
      newPassword: this.dataPass.get('newPassword')?.value,
      newRePassword: this.dataPass.get('newPassword')?.value,
    };
    if (this.adminUuid != undefined) {
      this.userService
        .changePassword(this.adminUuid, changePass)
        .subscribe(() => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.COMPANY.DATA-COMPANY.MESSAGE-CHANGE-PASS-OK'
            )
          );
        });
      this.isOpenWindow = !this.isOpenWindow;
    }
  }

  showPassOld(){
    this.showPasswordOld = !this.showPasswordOld;
  }

  showPassNew(){
    this.showPasswordNew = !this.showPasswordNew;
  }

  confirm() {
    this.confirmInactive = !this.confirmInactive;
    this.isCheckedStatus = true;
  }

  inactiveUser(){
    this.onSubmit();
    this.confirmInactive = false;
  }

  onSubmit() {
    this.isSubmit = true;

    // marca el input con error si no se rellena bien
    Object.keys(this.dataAdministrator.controls).forEach(controlName => {
      this.dataAdministrator.get(controlName)?.markAsTouched();
    });
    
    this.rolesIds.add(RoleEnum.ADMINISTRATIVE);
    if (this.isCheckedViewcis) {
      this.rolesIds.add(RoleEnum.ADMINISTRATIVE_VIEW_CIS);     
    }else {
      this.rolesIds.delete(RoleEnum.ADMINISTRATIVE_VIEW_CIS);
    }

    if (this.isCheckedSupervisor) {
      this.rolesIds.add(RoleEnum.ADMINISTRATIVE_SUPERVISOR);      
    }else {
      this.rolesIds.delete(RoleEnum.ADMINISTRATIVE_SUPERVISOR);
    }
  
  

    if (this.dataAdministrator.valid) {
      const userObject: UserOutput = {
        uuid: this.adminUuid,
        name: this.dataAdministrator.get('name')?.value,
        firstLastname: this.dataAdministrator.get('surname')?.value,
        secondLastname: this.dataAdministrator.get('surnametwo')?.value,
        mobile: this.dataAdministrator.get('phone')?.value,
        email: this.dataAdministrator.get('email')?.value,
        username: this.dataAdministrator.get('user')?.value,
        password:
          this.adminUuid !== undefined
            ? undefined
            : this.dataAdministrator.get('pass')?.value,
        rolesIds: Array.from(this.rolesIds),
        isActive: this.isCheckedStatus,
      };
     
      // para modificar los datos de un usuario
      if (this.adminUuid !== undefined) {
        this.userService.editUser(userObject).subscribe({
          next: user => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-MODIFY-ADMINISTRATOR'
            )
          );
          this.changeEdit();
          this.dataAdministrator.controls['dateregister'].setValue(user.creationDate?.toLocaleString());
          this.dataAdministrator.controls['datelastaccess'].setValue(user.lastAccessDate?.toLocaleString());
          this.dataAdministrator.controls['canceldate'].setValue(user.cancelDate?.toLocaleString()); 
        }
        });
      }
      // para dar de alta un usuario
      else {
        this.userService.newUser(userObject).subscribe(() => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-NEW-ADMINISTRATOR'
            )
          );
          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
          });
        });
      }
    } else {
      this.classInput = 'wrong';
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-ERROR'
        )
      );
    }
  }
}
