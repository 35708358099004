import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Gender from '@app/core/enums/gender.enum';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import { Patient, PatientInputDTO } from '@app/core/models/input/patient/patient.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { RgpdTemplate } from '@app/core/models/input/template/rgpd-template.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import { RgpdService } from '@app/core/services/rgpd/rgpd.service';
import { ElciValidators } from '@app/core/utils/validators';
import { faFileExport, faPenToSquare, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GenerateDocComponent } from '../generate-doc/generate-doc.component';
import { LoginService } from '@app/core/services/login/login.service';
import { User } from '@app/core/models/input/user.model';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import { catchError, of } from 'rxjs';
import { PhonePrefix } from '@app/core/models/input/phone-prefix/phone-prefix.model';
import { PhonePrefixService } from '@app/core/services/phone-prefix/phone-prefix.service';
import { FREEMIUM_ROLES } from '@app/core/constants/Permissions';
import RoleEnum from '@app/core/enums/role.enum';
import { RoleService } from '@app/core/services/role/role.service';
@Component({
  selector: 'app-data-patient',
  templateUrl: './data-patient.component.html',
  styleUrls: ['./data-patient.component.scss'],
})
export class DataPatientComponent implements OnInit {
  // Inputs
  @Input() inEdit?: boolean;
  @Input() isNewGDPR?: boolean;
  @Input() isFieldsDisabled = false;
  @Input() newPatient = true;
  @Input() openwindow = false;

  @ViewChild(GenerateDocComponent) generateDoc?: GenerateDocComponent;

  @Output() patientUuidEvent = new EventEmitter<string>();
  @Output() closeWindowEvent = new EventEmitter<boolean>();
  @Output() patientEvent = new EventEmitter<string>();
  // fortawesome
  faPenToSquare = faPenToSquare;
  faFileExport = faFileExport;
  faFileContract = faFileContract;

  // enum
  genderEnum = Gender;
  roleEnum = RoleEnum;

  // roles
  FREEMIUM_ROLES = FREEMIUM_ROLES;

  user?: User;

  // Representative sections
  legalRepresentative1 = false;
  legalRepresentative2 = false;
  id?: any;
  birthdate?: any;
  birthdate1?: any;
  birthdate2?: any;
  creationDate?: any;
  gender?: number;
  representativePatients?: Array<RepresentativePatientDTO> = [];
  isCheckedLegal1 = false;
  isCheckedLegal2 = false;
  isCheckedState = true;
  isCheckedGdpr = true;
  isCheckedWoman = false;
  isCheckedMan = true;
  isSubmit = false;
  classInput = '';
  isBlue = true;
  isFreemium = false;

  templates?: RgpdTemplate;
  selectedTemplate?: RgpdTemplate;
  templateUuid?: string;
  templateType?: string;

  dataPatient!: FormGroup;
  dataSignatureForm!: FormGroup;

  patientname?: string;
  patientfirstLastName?: string;
  patientsecondLastName?: string;
  phonePrefixes?: Array<PhonePrefix> = [];
  selectValue = null;
  selectedOutputTypeFromChild?: number;
  deviceFromChild?: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly patientService: PatientService,
    private readonly location: Location,
    private readonly rgpdService: RgpdService,
    private readonly loginService: LoginService,
    private readonly phonePrefixService: PhonePrefixService,
    public readonly roleService: RoleService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.id = param['uuid'];
    });
  }

  getPhonePrefix() {
    return this.phonePrefixService.getPhonePrefixApi().pipe(
      catchError(error => {
        console.error(`Error fetching prefix`);
        return of(new PhonePrefix());
      })
    );
  }

  // función que valida el sexo del paciente
  checked(gender: number) {
    if (!this.isFieldsDisabled) {
      if (gender == Gender.WOMAN) {
        this.isCheckedWoman = true;
        this.isCheckedMan = false;
      } else {
        this.isCheckedMan = true;
        this.isCheckedWoman = false;
      }
    }
  }

  // función que genera RGPD al crear paciente
  checkedGdpr() {
    const labelcheckGdpr = document.querySelector('.switch-button-label2');
    if (this.isCheckedGdpr) {
      labelcheckGdpr?.classList.remove('active');
      this.isCheckedGdpr = !this.isCheckedGdpr;
    } else {
      labelcheckGdpr?.classList.add('active');
      this.isCheckedGdpr = !this.isCheckedGdpr;
    }
  }

  // función que muestra los campos del primer representante legal cuando marcamos el check
  CheckedLegal1() {
    this.isCheckedLegal1 = !this.isCheckedLegal1;
    if (!this.isFieldsDisabled && this.isCheckedLegal1) {   
      this.generateRepresentativePatient1();     
    } else {  
      this.clearRepresentative1Validators();
      this.isCheckedLegal2 = false;
      this.legalRepresentative2 = false;
      this.clearRepresentative2Validators();
    }   
  
  }
  // función que muestra los campos del segundo representante legal cuando marcamos el segundo check
  CheckedLegal2() {
    this.isCheckedLegal2 = !this.isCheckedLegal2;
    if (!this.isFieldsDisabled && this.isCheckedLegal2) {
      this.generateRepresentativePatient2();
    } else {
      this.clearRepresentative2Validators();
    }
  }
  // validación del primer representante legal
  generateRepresentativePatient1() {
    this.dataPatient.get('name1')?.setValidators([Validators.required, Validators.minLength(3)])
    this.dataPatient.get('surname1')?.setValidators([Validators.required, Validators.minLength(3)])
    this.dataPatient.get('nif1')?.setValidators([Validators.required, Validators.pattern(/^[0-9a-zA-Z]{9,15}$/)])
    this.dataPatient.get('datebirth1')?.setValidators([Validators.required])
    this.dataPatient.get('phone1')?.setValidators( [Validators.pattern(/^\d{9,15}$/)])
    this.dataPatient.get('email1')?.setValidators( [ElciValidators.emailValidator])
    this.removeRequiredValidator('phone');
    this.removeRequiredValidator('email');
    this.dataPatient.updateValueAndValidity();
  }
  // validación del segundo representante legal
  generateRepresentativePatient2() {
    this.dataPatient.get('name2')?.setValidators([Validators.required, Validators.minLength(3)])
    this.dataPatient.get('surname2')?.setValidators([Validators.required, Validators.minLength(3)])
    this.dataPatient.get('nif2')?.setValidators([Validators.required, Validators.pattern(/^[0-9a-zA-Z]{9,15}$/)])
    this.dataPatient.get('datebirth2')?.setValidators([Validators.required])
    this.dataPatient.get('phone2')?.setValidators( [Validators.pattern(/^\d{9,15}$/)])
    this.dataPatient.get('email2')?.setValidators( [ElciValidators.emailValidator])
    this.dataPatient.updateValueAndValidity();
  }
// quita los controles de validación del primer representante legal si se desmarca el check
  clearRepresentative1Validators() {
    ['id1', 'name1', 'surname1', 'nif1', 'phone1', 'email1', 'datebirth1'].forEach(control => {
      if (this.dataPatient.get(control)) {
        this.dataPatient.get(control)?.clearValidators();
        this.dataPatient.get(control)?.updateValueAndValidity();
      }
    });
  }
// quita los controles de validación del segundo representante legal si se desmarca el check
  clearRepresentative2Validators() {
    ['id2', 'name2', 'surname2', 'nif2', 'phone2', 'email2', 'datebirth2'].forEach(control => {
      if (this.dataPatient.get(control)) {
        this.dataPatient.get(control)?.clearValidators();
        this.dataPatient.get(control)?.updateValueAndValidity();
      }
    });
  }

  // función para editar el formulario y poder modificar datos
  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled;
    this.toggleForm();
    this.dataPatient.controls['id'].disable();
    this.dataPatient.controls['external_id'].disable();
    if(!this.isFieldsDisabled){
        if(this.dataPatient.controls['phonePrefix'].value === null){
          this.dataPatient.controls['phonePrefix'].setValue(this.user?.defaultPhonePrefix);
        }
        if(this.dataPatient.controls['phonePrefix1'].value === null){
          this.dataPatient.controls['phonePrefix1'].setValue(this.user?.defaultPhonePrefix);
        }
        if(this.dataPatient.controls['phonePrefix2'].value === null){
          this.dataPatient.controls['phonePrefix2'].setValue(this.user?.defaultPhonePrefix);
        }
    }
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataPatient.disable();
      this.isBlue = false;
    } else {
      this.dataPatient.enable();
      this.isBlue = true;

    }
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataPatient.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  // Validamos e inicializamos formulario
  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();

    this.dataPatient = new FormGroup({
      id: new FormControl({ value: '', disabled: false }),
      external_id: new FormControl({ value: '', disabled: false }),
      name: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surnametwo: new FormControl({ value: '', disabled: false }),
      gender: new FormControl({ value: '', disabled: false }),

      nif: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z]{9,15}$/),
      ]),
      datebirth: new FormControl({ value: '', disabled: false }, [Validators.required]),
      phonePrefix: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      phone: new FormControl({ value: '', disabled: false }, [Validators.pattern(/^\d{9,15}$/)]),
      email: new FormControl({ value: '', disabled: false }, [ElciValidators.emailValidator]),
      history: new FormControl({ value: '', disabled: false }),

      state: new FormControl(),
      gdpr: new FormControl(),
      legalre1: new FormControl(),
      id1: new FormControl({ value: '', disabled: false }),
      name1: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname1: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surnametwo1: new FormControl({ value: '', disabled: false }),
      parenhood1: new FormControl({ value: '', disabled: false }),

      nif1: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z]{9,15}$/),
      ]),
      datebirth1: new FormControl({ value: '', disabled: false }, [Validators.required]),
      phonePrefix1: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      phone1: new FormControl({ value: '', disabled: false }, [Validators.pattern(/^\d{9,15}$/)]),
      email1: new FormControl({ value: '', disabled: false }, [ElciValidators.emailValidator]),

      legalre2: new FormControl(),
      id2: new FormControl({ value: '', disabled: false }),
      name2: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname2: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surnametwo2: new FormControl({ value: '', disabled: false }),
      parenhood2: new FormControl({ value: '', disabled: false }),

      nif2: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z]{9,15}$/),
      ]),
      datebirth2: new FormControl({ value: '', disabled: false }, [Validators.required]),
      phonePrefix2: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      phone2: new FormControl({ value: '', disabled: false }, [Validators.pattern(/^\d{9,15}$/)]),
      email2: new FormControl({ value: '', disabled: false }, [ElciValidators.emailValidator]),
    });
    
    this.toggleForm();
    
    //Consulta de los prefijos de país
    this.getPhonePrefix().subscribe({
      next: data => {
        if (Array.isArray(data)) {
          this.phonePrefixes = data;
        }
      },
    });


    // Consulta de cada paciente a la api
    if (this.id !== undefined) {
      this.patientService.getPatientByUuid(this.id).subscribe({
        next: data => {
          this.patientEvent.emit(`${data.name ?? ''} ${data.firstLastName ?? ''} ${data.secondLastName ?? ''}`);
          if (data.genderId == Gender.WOMAN) {
            this.isCheckedWoman = true;
            this.isCheckedMan = false;
          } else {
            this.isCheckedMan = true;
            this.isCheckedWoman = false;
          }
          const labelCheck = document.querySelector('.switch-button-label');
          if (data.isActive) {
            labelCheck?.classList.add('active');
            this.isCheckedState = true;
          }

          if (data.representativePatients?.[0]?.id) {
            if (data.representativePatients?.[0].active) {
              this.isCheckedLegal1 = true;
              this.legalRepresentative1 = true;
            }else{
              this.clearRepresentative1Validators();
            }

            this.generateRepresentativePatient1();
            this.dataPatient.controls['id1'].setValue(data.representativePatients?.[0]?.id);
            this.dataPatient.controls['name1'].setValue(data.representativePatients?.[0]?.name);
            this.dataPatient.controls['surname1'].setValue(data.representativePatients?.[0]?.firstLastName);
            this.dataPatient.controls['surnametwo1'].setValue(data.representativePatients?.[0]?.secondLastName);
            this.dataPatient.controls['parenhood1'].setValue(data.representativePatients?.[0]?.relationship);
            this.dataPatient.controls['nif1'].setValue(data.representativePatients?.[0]?.documentNumber);
            this.dataPatient.controls['datebirth1'].setValue(data.representativePatients?.[0]?.birthdate);
            this.dataPatient.controls['phonePrefix1'].setValue(data.representativePatients?.[0]?.phonePrefix);
            this.dataPatient.controls['phone1'].setValue(data.representativePatients?.[0]?.mobile);
            this.dataPatient.controls['email1'].setValue(data.representativePatients?.[0]?.email);
          }else{
            this.clearRepresentative1Validators();
          }

          if (data.representativePatients?.[1]?.id) {
            if (data.representativePatients?.[1].active) {
              this.isCheckedLegal2 = true;
              this.legalRepresentative2 = true;
            }else{
              this.clearRepresentative2Validators();
            }
            this.generateRepresentativePatient2();
            this.dataPatient.controls['id2'].setValue(data.representativePatients?.[1]?.id);
            this.dataPatient.controls['name2'].setValue(data.representativePatients?.[1]?.name);
            this.dataPatient.controls['surname2'].setValue(data.representativePatients?.[1]?.firstLastName);
            this.dataPatient.controls['surnametwo2'].setValue(data.representativePatients?.[1]?.secondLastName);
            this.dataPatient.controls['parenhood2'].setValue(data.representativePatients?.[1]?.relationship);
            this.dataPatient.controls['nif2'].setValue(data.representativePatients?.[1]?.documentNumber);
            this.dataPatient.controls['datebirth2'].setValue(data.representativePatients?.[1]?.birthdate);
            this.dataPatient.controls['phonePrefix2'].setValue(data.representativePatients?.[1]?.phonePrefix);

            this.dataPatient.controls['phone2'].setValue(data.representativePatients?.[1]?.mobile);
            this.dataPatient.controls['email2'].setValue(data.representativePatients?.[1]?.email);
          }else{
            this.clearRepresentative2Validators();
          }
            this.dataPatient.controls['id'].setValue(data.id);
            this.dataPatient.controls['external_id'].setValue(data.externalId);
            this.dataPatient.controls['name'].setValue(data.name);
            this.dataPatient.controls['surname'].setValue(data.firstLastName);
            this.dataPatient.controls['surnametwo'].setValue(data.secondLastName);
            this.dataPatient.controls['gender'].setValue(this.gender);
            this.dataPatient.controls['nif'].setValue(data.documentNumber);
            this.dataPatient.controls['datebirth'].setValue(data.birthdate);
            this.dataPatient.controls['phonePrefix'].setValue(data.phonePrefix);
            this.dataPatient.controls['phone'].setValue(data.mobile);
            this.dataPatient.controls['email'].setValue(data.email);
            this.dataPatient.controls['history'].setValue(data.healthHistoryExt);
            this.dataPatient.controls['state'].setValue(data.isActive);

        },

      });

    }else{
      this.clearRepresentative1Validators();
      this.clearRepresentative2Validators();
    }
    // Consulta de las RGPD's disponibles (de momento una)
    this.rgpdService.getRgpdDefaultTemplate(this.user?.language?.locale ?? DEFAULT_ELCI_LANGUAGE).subscribe({
      next: dataRGPD => {
        this.templateUuid = dataRGPD.uuid;
        this.templateType = dataRGPD.type;
      },
    });
  }

  // Accedo al formulario que genera el RGPD que está en el componente generate-doc
  handleChildFormGroup(childFormGroup: FormGroup) {
    this.dataSignatureForm = childFormGroup;
  }

  // botón recarga de la página
  refresh() {
    if (this.openwindow) {
      this.closeWindowEvent.emit();
    } else {
      this.location.back();
    }
  }

  onSelectedOutputTypeChange(newType: number) {
    this.selectedOutputTypeFromChild = newType;
    if(OutputTypeEnum.DIGITAL_REMOTE == newType){
      this.validRemoteOutputType();
    }else{
      this.removeValidRemoteOutputType();
    }
  }
  onSelectedDevice(newType: string) {
    this.deviceFromChild = newType;    
  }

  validEmailsAndPhones(){
    let required = false;
    if(OutputTypeEnum.DIGITAL_REMOTE == this.selectedOutputTypeFromChild && this.isCheckedGdpr)
      required = true;
    if(required && !this.isCheckedLegal1){
      this.addValidator('phone', required);
      this.addValidator('email', required);
    }else{
      this.addValidator('phone', false);
      this.addValidator('email', false);
    }
    if(this.isCheckedLegal1){
      this.addValidator('phone1', required);
      this.addValidator('email1', required);
    }else{
      this.addValidator('phone1', false);
      this.addValidator('email1', false);
    }
    if(this.isCheckedLegal2){
      this.addValidator('phone2', required);
      this.addValidator('email2', required);
    }else{
      this.addValidator('phone2', false);
      this.addValidator('email2', false);
    }
  }

  validRemoteOutputType(){
    if(this.isCheckedLegal1){
      this.addValidator('phone1', true);
      this.addValidator('email1', true);
      if(this.isCheckedLegal2){
        this.addValidator('phone2', true);
        this.addValidator('email2', true);
      }
    }else{
      this.addValidator('phone', true);
      this.addValidator('email', true);
    }
  }

  removeValidRemoteOutputType(){
    if(this.isCheckedLegal1){
      this.removeRequiredValidator('phone1');
      this.removeRequiredValidator('email1');
      if(this.isCheckedLegal2){
        this.removeRequiredValidator('phone2');
        this.removeRequiredValidator('email2');
      }
    }else{
      this.removeRequiredValidator('phone');
      this.removeRequiredValidator('email');
    }
  }

  removeRequiredValidator(controlName: string){
    const variableControl = this.dataPatient.get(controlName);
    if (variableControl) {
      variableControl.setValidators([]);
      variableControl.updateValueAndValidity();
    }
  }

  removeRequiredValidator1(controlName: string) {
    const variableControl = this.dataPatient.get(controlName);
    if (variableControl) {
      const currentValidators = variableControl.validator ? [variableControl.validator] : [];
      const updatedValidators = currentValidators.filter(validator => validator !== Validators.required);
      variableControl.setValidators(updatedValidators);
      variableControl.updateValueAndValidity();
      
    }
  }

  addValidator(controlName: string, required: boolean){
    const variableControl = this.dataPatient.get(controlName);
    if (variableControl) {
      const validators = [];
      if (required) {
        validators.push(Validators.required);
      }
      if(controlName.includes("phone"))
        validators.push(Validators.pattern(/^\d{9,15}$/));
      if(controlName.includes("email"))
        validators.push(ElciValidators.emailValidator);
      variableControl.setValidators(validators);
      variableControl.updateValueAndValidity();
    }
  }

  // envío de formulario
  onSubmit() {
    this.isSubmit = true;
    this.validEmailsAndPhones();
    Object.keys(this.dataPatient.controls).forEach(controlName => {
      this.dataPatient.get(controlName)?.markAsTouched();
    });

    // validamos que si tiene RL, el NIF del paciente no es obligatorio
    const documentControl = this.dataPatient.get('nif');
    if (this.isCheckedLegal1) {
      documentControl?.clearValidators();
    } else {
      documentControl?.setValidators([Validators.required]);
    }
    documentControl?.updateValueAndValidity();   
   
    if (this.dataPatient.valid) {
      let gender = Gender.WOMAN;
      let state = true;
      let Rgpd = false;
      const representativePatientList: RepresentativePatientDTO[] = [];
      if (
        this.dataPatient.get('name1')?.value !== null &&
        this.dataPatient.get('name1')?.value !== '' &&
        this.dataPatient.get('name1')?.value !== undefined &&
        this.isCheckedLegal1
      ) {
        // recogemos datos si tiene un representante legal
        const representativeObject: RepresentativePatientDTO = {
          id: this.dataPatient.get('id1')?.value,
          name: this.dataPatient.get('name1')?.value,
          firstLastName: this.dataPatient.get('surname1')?.value,
          secondLastName: this.dataPatient.get('surnametwo1')?.value,
          documentNumber: this.dataPatient.get('nif1')?.value,
          birthdate: this.dataPatient.get('datebirth1')?.value == '' ? null : this.dataPatient.get('datebirth1')?.value,
          email: this.dataPatient.get('email1')?.value,
          phonePrefix:this.dataPatient.get('phonePrefix1')?.value,
          mobile: this.dataPatient.get('phone1')?.value,
          relationship: this.dataPatient.get('parenhood1')?.value,
          isMainRepresentativePatient: true,
        };
        representativePatientList.push(representativeObject);
      }
      if (
        this.dataPatient.get('name2')?.value !== null &&
        this.dataPatient.get('name2')?.value !== '' &&
        this.dataPatient.get('name2')?.value !== undefined &&
        this.isCheckedLegal2
      ) {
        // recogemos datos si tiene un segundo representante legal

        const representativeObject2: RepresentativePatientDTO = {
          id: this.dataPatient.get('id2')?.value,
          name: this.dataPatient.get('name2')?.value,
          firstLastName: this.dataPatient.get('surname2')?.value,
          secondLastName: this.dataPatient.get('surnametwo2')?.value,
          documentNumber: this.dataPatient.get('nif2')?.value,
          birthdate: this.dataPatient.get('datebirth2')?.value == '' ? null : this.dataPatient.get('datebirth2')?.value,
          email: this.dataPatient.get('email2')?.value,
          phonePrefix:this.dataPatient.get('phonePrefix2')?.value,
          mobile: this.dataPatient.get('phone2')?.value,
          relationship: this.dataPatient.get('parenhood2')?.value,
          isMainRepresentativePatient: false,
        };
        representativePatientList.push(representativeObject2);
      }

      if (this.isCheckedMan) {
        gender = Gender.MAN;
      }

      if (!this.isCheckedState) {
        state = false;
      }

      if (this.isCheckedGdpr) {
        Rgpd = true;
      }

      if (this.generateDoc && Rgpd) {
        this.generateDoc.onSubmit();
      }

      // recogemos los datos del paciente y los representantes legales si los tuviera
      const patientObject: PatientInputDTO = {
        uuid: this.id,
        name: this.dataPatient.get('name')?.value,
        firstLastName: this.dataPatient.get('surname')?.value,
        secondLastName: this.dataPatient.get('surnametwo')?.value,
        documentNumber: this.dataPatient.get('nif')?.value,
        genderId: gender,
        birthdate: this.dataPatient.get('datebirth')?.value,
        email: this.dataPatient.get('email')?.value,
        phonePrefix:this.dataPatient.get('phonePrefix')?.value,
        mobile: this.dataPatient.get('phone')?.value,
        healthHistoryExt: this.dataPatient.get('history')?.value,
        isActive: state,
        representativePatients: representativePatientList,
        generateRGPD: Rgpd,
        outputType: this.selectedOutputTypeFromChild,
        templateUuid: this.templateUuid,
        templateType: this.templateType,
        device: this.deviceFromChild,
      };
      // para modificar los datos de un paciente
      if (this.id !== undefined) {
        this.patientService.editPatient(patientObject).subscribe((respuesta: Patient) => {
          if (respuesta.representativePatients != undefined) {
            respuesta.representativePatients.forEach((item, index) => {
              this.dataPatient.controls['id' + (index + 1)].setValue(item.id);
              this.dataPatient.controls['phonePrefix'+ (index+1)].setValue(item.phonePrefix);
            });
          }
          this.dataPatient.controls['phonePrefix'].setValue(respuesta.phonePrefix);

          this.alertService.success(this.translate.instant('PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-MODIFY-OK'));

          this.changeEdit();
        });
      }
      // para dar de alta un paciente
      else {
        this.patientService.newPatient(patientObject).subscribe((respuesta: PatientInputDTO) => {
          if (this.newPatient) {
            this.alertService.success(this.translate.instant('PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-OK'));
            this.router.navigate(['../'], {
              relativeTo: this.activatedRoute,
            });
          } else {
            this.patientUuidEvent.emit(respuesta.uuid ?? '');
          }
        });
      }
    } else {
      this.classInput = 'wrong';
      this.alertService.error(this.translate.instant('PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'));
    }
  }
}
