import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { SharedComponentModule } from '../shared/components/shared-component.module';
import { DataPatientComponent } from './components/data-patient/data-patient.component';
import { DigitalDocumentTemplateComponent } from './components/digital-document-template/digital-document-template.component';
import { SpecialsDocsComponent } from './components/digital-documents/digital-documents.component';
import { DocumentsPatientComponent } from './components/documents-patient/documents-patient.component';
import { GenerateDocComponent } from './components/generate-doc/generate-doc.component';
import { RgpdLeavesComponent } from './components/rgpd-leaves/rgpd-leaves.component';
import { RgpdTemplateComponent } from './components/rgpd-template/rgpd-template.component';
import { FilePatientComponent } from './pages/file-patient/file-patient.component';
import { GenDdsComponent } from './pages/gen-dds/gen-dds.component';
import { GenDocsComponent } from './pages/gen-docs/gen-docs.component';
import { ListPatientsComponent } from './pages/list-patients/list-patients.component';
import { NewPatientComponent } from './pages/new-patient/new-patient.component';
import { GenDocsQuickComponent } from './pages/gen-docs-quick/gen-docs-quick.component';
import { FilePatientQuickComponent } from './pages/file-patient-quick/file-patient-quick.component';
import { AdditionalNotesComponent } from './components/additional-notes/additional-notes.component';

@NgModule({
  declarations: [
    ListPatientsComponent,
    FilePatientComponent,
    DataPatientComponent,
    DocumentsPatientComponent,
    RgpdLeavesComponent,
    SpecialsDocsComponent,
    GenerateDocComponent,
    GenDocsComponent,
    NewPatientComponent,
    RgpdTemplateComponent,
    DigitalDocumentTemplateComponent,
    GenDdsComponent,
    GenDocsQuickComponent,
    FilePatientQuickComponent,
    AdditionalNotesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentModule,
  ],
  exports: [
    DataPatientComponent,
    FilePatientComponent
  ],
})
export class PatientsModule {}
