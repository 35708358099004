<div class="wrapper">
    <div class="maxwidth">
         <h4 class="title tipo-second-color bold">{{ "PRIVATE.ADMINISTRATOR.FILE-ADMINISTRATOR.TITLE" | translate}}</h4>
    <div class="tabset">
      <app-data-administrator
        [inEdit]="true"
        [isFieldsDisabled]="true"
        (userData)="receiveAdminData($event)"></app-data-administrator>
    </div>
  </div>
</div>
