
    <div class="box box2"> 
        <h4 class="tipo-second-color title">{{'SUPER-ADMIN.DATA-PROFESSIONAL.TITLE-DATA-CREDENTIAL' | translate}}</h4>
        <div class="buttons button_edit" >
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataProfessional" class="form_dataprofessional" >           
            <div class="input">
                <label for="statusCredential" class="statusCredential">{{'SUPER-ADMIN.DATA-PROFESSIONAL.DATA-STATUS-CREDENTIAL' | translate}}</label>                
                <input type="text" formControlName="statusCredential" id="statusCredential" [class.back_blue]="this.inEdition">  
            </div>
            <div class="input">
                <label for="dateOnCredential" class="dateOnCredential">{{'SUPER-ADMIN.DATA-PROFESSIONAL.DATA-DATE-ON-CREDENTIAL' | translate}}</label>                
                <input type="date" formControlName="dateOnCredential" id="dateOnCredential" [class.back_blue]="this.inEdition">  
            </div>
            <div class="input">
                <label for="dateOffCredential" class="dateOffCredential">{{'SUPER-ADMIN.DATA-PROFESSIONAL.DATA-DATE-OFF-CREDENTIAL' | translate}}</label>                
                <input type="date" formControlName="dateOffCredential" id="dateOffCredential" [class.back_blue]="this.inEdition">  
            </div>
            <div class="input">
                <label for="status" class="status">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-STATUS' | translate}}</label>                
                <input type="text" formControlName="status" id="status" [class.back_blue]="this.inEdition">  
            </div>
           
            <div class="buttons" *ngIf="inEdit || isFieldsDisabled">                   
                <button type="submit" class="button tipo-white-color back-blue-color">
                    <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button class="button tipo-main-color back-grey-color">
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>    
        </form>
    </div>
