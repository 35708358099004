import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import LanguageEnum from '@app/core/enums/language.enum';
import RoleEnum from '@app/core/enums/role.enum';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import { ChangePassword } from '@app/core/models/input/change-pass.model';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { Device } from '@app/core/models/input/device.model';
import { Role } from '@app/core/models/input/role.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import { State } from '@app/core/models/input/state.model';
import { UserAdditionalData } from '@app/core/models/input/user-additional-data.model';
import { User } from '@app/core/models/input/user.model';
import { UserOutput } from '@app/core/models/output/user-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import { DeviceService } from '@app/core/services/device/device.service';
import { LoginService } from '@app/core/services/login/login.service';
import { StateService } from '@app/core/services/state/state.service';
import { UserService } from '@app/core/services/user/user.service';
import { ElciValidators } from '@app/core/utils/validators';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import {
  faCircleXmark,
  faFileExport,
  faPenToSquare,
  faEye,
  faEyeSlash,
  faCheckDouble
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-doctor',
  templateUrl: './data-doctor.component.html',
  styleUrls: ['./data-doctor.component.scss'],
})
export class DataDoctorComponent implements OnInit {
  // fontawesome
  faPenToSquare = faPenToSquare;
  faFileExport = faFileExport;
  faCircleXmark = faCircleXmark;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCheckDouble = faCheckDouble;

  // Inputs
  @Input()
  inEdit?: boolean;

  @Input()
  isFieldsDisabled = false;
  isNew = true;

  dataDoctor!: FormGroup;
  dataPass!: FormGroup;
  selectValue = null;
  selectValueDev!: string;
  userValue = null;
  doctorUuid?: string;
  isSubmit = false;
  inEdition = true;
  user?: User;
  isOpenWindow = false;
  classInput = '';
  isBlue = true;
  inUser = false;
  isCheckedStatus = true;
  isCheckedCredential = false;
  devices?: Device[];
  states?: State[];
  specialty?: Specialty[] = [];
  specialties?: Specialty[];
  subscriptions?: SubscriptionTypes[] = [];
  isBiometric = false;
  isSameUser = false;
  showPasswordOld = false;
  showPasswordNew = false;
  confirmInactive = false;
  confirmCredential = false; 
  isSudo = false;

  registerDate!: string | undefined;
  lastAccessDate!: string | undefined;
  cancelDate!: string | undefined;
  userStatusId?: number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private translate: TranslateService,
    private deviceService: DeviceService,
    private userService: UserService,
    private loginService: LoginService,
    private specialtiesService: SpecialtiesService,
    private cacheImplService: CacheImplService,
    private stateService: StateService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.doctorUuid = param['uuid'];
    });
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataDoctor.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();   

     // Comprobar el tipo de suscripción y si es solo papel, no mostrar botón Biométrica
     const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];
     this.isBiometric = !!subscriptionType.find(subscription => subscription.id === SubscriptionTypeEnum.BIOMETRIC);

    // Validamos e inicializamos formulario
    this.dataDoctor = new FormGroup({
      name: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      surname: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      surnametwo: new FormControl({ value: '', disabled: false }),

      nif: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^\w{9,15}$/),
      ]),

      datebirth: new FormControl({ value: '', disabled: false }),

      numcol: new FormControl({ value: '', disabled: !this.inUser }, [
        Validators.required,
        Validators.pattern(/^\d{6,9}$/),
      ]),

      state: new FormControl({ value: '', disabled: false }),

      phone: new FormControl({ value: '', disabled: false }, [
        Validators.pattern(/^\d{9,15}$/),
      ]),

      email: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        ElciValidators.emailValidator,
      ]),

      service: new FormControl({ value: '', disabled: false }),

      user: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),

      pass: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),

      generateSSICredential: new FormControl({ value: '', disabled: false }),

      status: new FormControl(),

      dateregister: new FormControl({ value: '', disabled: false }),
      datelastaccess: new FormControl({ value: '', disabled: false }),
      canceldate: new FormControl({ value: '', disabled: false }),

      device: new FormControl({ value: '', disabled: false }),

      credential: new FormControl({ value: '', disabled: false }),
    });
    this.toggleForm();

    this.dataPass = new FormGroup({
      oldPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      newPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    });

    // consultamos los dispositivos asignados al usuario
    if(this.isBiometric){
      this.deviceService.getUserDevices().subscribe({
        next: devices => {
          this.deviceService.setDevices(devices)
          this.devices = devices;
        },
      });
    }


     // Comprobar el tipo de role del usuario logueado y si es sudo enviamos 0 para que nos devuelva las provincias de los dos países
     const roleType: Role[] = this.user.roles ?? [];
     this.isSudo = !!roleType.find(roles => roles.id === RoleEnum.ADMIN);
     
    // consultamos las provincias asignadas al país
    this.stateService
      .getStateByCountryId(
        this.user?.language?.id != undefined && !this.isSudo ? this.user.language.id : 0
      )
      .subscribe({
        next: states => {
          this.states = states;
        },
      });

    // consultamos las especialidades
    this.specialtiesService.getSpecialties().subscribe({
      next: specialties => {
        this.specialties = specialties;
      },
    });

    // consultamos el usuario
    if (this.doctorUuid !== undefined) {
      this.userService.getUserByUuid(this.doctorUuid).subscribe({
        next: data => {
          const labelCheck = document.querySelector('.switch-button-label');
          if (data.isActive && UserStatusEnum.ACTIVO === data.userStatus?.id) {
            labelCheck?.classList.add('active');
            this.isCheckedStatus = true;
          } else {
            labelCheck?.classList.remove('active');
            this.isCheckedStatus = false;
          }
          this.userStatusId = data.userStatus?.id
          this.isCheckedCredential = data.generateSSICredential ? data.generateSSICredential : false;
          
          // para quitar del listado de todas las especialidades la especialidad que se añada a la selección
          this.specialty = data.specialty;
          this.specialty?.forEach(specialty => {
            this.specialties = this.specialties?.filter(
              specialtyFilter => specialtyFilter.id !== specialty.id
            );
          });
          this.selectValueDev =
            data.userDevice?.description != undefined
              ? data.userDevice?.description
              : '';

          // pasar la hora del sistema a la hora local
          this.registerDate = data.creationDate?.toLocaleString();
          this.lastAccessDate = data.lastAccessDate?.toLocaleString();
          this.cancelDate = data.cancelDate?.toLocaleString();
          
          this.dataDoctor.controls['name'].setValue(data.name);
          this.dataDoctor.controls['surname'].setValue(data.firstLastname);
          this.dataDoctor.controls['surnametwo'].setValue(data.secondLastname);
          this.dataDoctor.controls['nif'].setValue(data.dniCif);
          this.dataDoctor.controls['datebirth'].setValue(data.birthDate);
          this.dataDoctor.controls['numcol'].setValue(data.username);
          this.dataDoctor.controls['state'].setValue(data.state?.id);
          this.dataDoctor.controls['phone'].setValue(data.mobile);
          this.dataDoctor.controls['email'].setValue(data.email);
          this.dataDoctor.controls['service'].setValue(data.userAdditionalData?.service);
          this.dataDoctor.controls['user'].setValue(data.username);
          this.dataDoctor.controls['pass'].setValue('******');
          this.dataDoctor.controls['device'].setValue(this.selectValueDev);
          this.doctorUuid = data.uuid;

          if (this.user?.uuid === data.uuid) {
            this.isSameUser = true;
          }
        },
      });
    } else {
      this.inUser = true;
    }
  }
  // para quitar una especialidad
  deleteItem(i: number, specialty: Specialty) {
    this.specialty?.splice(i, 1);
    this.specialties?.push(specialty);
    this.specialties = this.specialties?.sort((a, b) => {
      const descriptionA = a.description ?? '';
      const descriptionB = b.description ?? '';
      return descriptionA.localeCompare(descriptionB);
    });
  }
  // para añadir una especialidad
  addItem(i: number, specialtyAdd: Specialty) { 
    if (this.specialty) {
      if (this.isSudo || this.specialty.length < 2) {
        this.specialties?.splice(i, 1);
        this.specialty.push(specialtyAdd);
      }
    }
  }


  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled;    
    this.toggleForm();
    this.isNew = !this.isNew;
    if (this.doctorUuid !== undefined) {
      this.inUser = false;
      this.dataDoctor.controls['numcol'].disable();
      this.dataDoctor.controls['user'].disable();
      this.dataDoctor.controls['pass'].disable();      
    }
    if (this.isSameUser && this.inEdition && !this.isFieldsDisabled) {         
      this.dataDoctor.controls['service'].disable();         
      
    }
    
    
  }

  checkedCredential() {
    if (this.inEdition){
    this.isCheckedCredential = !this.isCheckedCredential;
    this.confirmCredential = !this.isCheckedCredential;
    }
  }

  // valida el estado del usuario
  checkedStatus() {
    if (this.inEdition) {
      const labelcheck = document.querySelector('.switch-button-label');
      if (this.isCheckedStatus) {
        labelcheck?.classList.remove('active');
        this.isCheckedStatus = false;
        this.confirmInactive = true;
      }
      else if(this.isSudo) {
        labelcheck?.classList.add('active');
        this.isCheckedStatus = true;
        this.confirmInactive = false;        
      }
    }
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataDoctor.disable();
      this.inEdition = false;
    } else {
      this.dataDoctor.enable();
      this.inEdition = true;
    }
  }

  // botón recarga de la página
  refresh() {
    window.location.reload();
  }

  // cambiar password
  openWindow() {
    this.isOpenWindow = !this.isOpenWindow;
  }

  changePass() {
    const changePass: ChangePassword = {
      oldPassword: this.dataPass.get('oldPassword')?.value,
      newPassword: this.dataPass.get('newPassword')?.value,
      newRePassword: this.dataPass.get('newPassword')?.value,
    };

    if (this.doctorUuid != undefined) {
      this.userService
        .changePassword(this.doctorUuid, changePass)
        .subscribe(() => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.COMPANY.DATA-COMPANY.MESSAGE-CHANGE-PASS-OK'
            )
          );
        });
      this.isOpenWindow = !this.isOpenWindow;
    }
  }

  showPassOld(){
    this.showPasswordOld = !this.showPasswordOld;
  }

  showPassNew(){
    this.showPasswordNew = !this.showPasswordNew;
  }

  confirm() {
    this.confirmInactive = !this.confirmInactive;
    this.isCheckedStatus = true;
  }

  confirmRevokeCredential() {
    this.confirmCredential = !this.confirmCredential;
    this.isCheckedCredential = true;
  }

  inactiveUser(){
    this.onSubmit();
    this.confirmInactive = false;
  }

  revokeCredential(){
    this.onSubmit();
    this.confirmCredential = false;
  }

  editField(){
    const error = document.querySelector('.messageSpecialty');
    const box = document.querySelector('.specialty_select');
    error?.classList.remove('wrongtext');
    box?.classList.remove('wrong');
  }

  onSubmit() {
    this.isSubmit = true;

    // marca el input con error si no se rellena bien
    Object.keys(this.dataDoctor.controls).forEach(controlName => {
      this.dataDoctor.get(controlName)?.markAsTouched();
    });

     // validamos el país del usuario logueado y establecemos el pattern del número de colegiado diferente para España y Ecuador
     const documentControl = this.dataDoctor.get('numcol');
     if (this.user?.language?.id === LanguageEnum.es_ES ) {
       documentControl?.setValidators([Validators.pattern(/^\d{6,9}$/),]);

     } else {
       documentControl?.setValidators([Validators.pattern(/^\d{10}$/),]);
     }
     documentControl?.updateValueAndValidity();

    const userAdditionalDataService: UserAdditionalData =
      new UserAdditionalData();
    userAdditionalDataService.service = this.dataDoctor.get('service')?.value;

    if (
      this.dataDoctor.valid &&
      this.specialty != undefined &&
      this.specialty?.length > 0
    ) {
      // extraemos solo los id's del array de especialidades que contiene id's y description de cada especialidad
      const specialtyIds: number[] =
        this.specialty?.map(specialty => specialty?.id ?? 0) ?? [];

      const userObject: UserOutput = {
        uuid: this.doctorUuid,
        name: this.dataDoctor.get('name')?.value,
        firstLastname: this.dataDoctor.get('surname')?.value,
        secondLastname: this.dataDoctor.get('surnametwo')?.value,
        dniCif: this.dataDoctor.get('nif')?.value,
        birthDate:
           this.dataDoctor.get('datebirth')?.value == ''
           ? null
           : this.dataDoctor.get('datebirth')?.value,
        username: this.dataDoctor.get('numcol')?.value,
        stateId: this.dataDoctor.get('state')?.value,
        mobile: this.dataDoctor.get('phone')?.value,
        email: this.dataDoctor.get('email')?.value,
        userAdditionalData: userAdditionalDataService,
        specialtiesIds: specialtyIds,
        password:
          this.doctorUuid !== undefined
            ? undefined
            : this.dataDoctor.get('pass')?.value,
        userDevice: this.dataDoctor.get('device')?.value,
        rolesIds: [RoleEnum.COMPANY_DOCTOR],
        isActive: this.userStatusId === UserStatusEnum.PENDIENTE  ? true : this.isCheckedStatus,
        generateSSICredential: this.isCheckedCredential
        
      };

      // para modificar los datos de un usuario
      if (this.doctorUuid !== undefined) {
        this.userService.editUser(userObject).subscribe({
          next: user => {
            if (this.user) {
              this.user.userDevice = user.userDevice;
              this.cacheImplService.setItem('user', JSON.stringify(this.user)); // Update the user device in the app
              this.loginService.setUserValue(this.user ?? null);
             
             
            }

            this.alertService.success(
              this.translate.instant(
                'PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-MODIFY-DOCTOR'
              )
            );
      
            this.changeEdit();
            this.dataDoctor.controls['dateregister'].setValue(user.creationDate?.toLocaleString());
            this.dataDoctor.controls['datelastaccess'].setValue(user.lastAccessDate?.toLocaleString());
            this.dataDoctor.controls['canceldate'].setValue(user.cancelDate?.toLocaleString());          
          }
        });
      }
      // para dar de alta un usuario
      else {
        this.userService.newUser(userObject).subscribe(() => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-NEW-DOCTOR'
            )
          );
         
          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
          });
        });
      }
    } else {
      this.classInput = 'wrong';
      this.alertService.error(
        this.translate.instant('PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-ERROR')
      );

      if(this.specialty && this.specialty?.length === 0){
        const error = document.querySelector('.messageSpecialty');
        const box = document.querySelector('.specialty_select');
        error?.classList.add('wrongtext');
        box?.classList.add('wrong');
      }
    }
  }
}
