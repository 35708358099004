<div class="wrapper">
    <div class="maxwidth">
        <h4 class="title tipo-second-color bold">{{ "PRIVATE.DOCTOR.NEW-DOCTOR.TITLE" | translate }}</h4>
        <div class="newdoctor">
            <app-data-doctor [inEdit]="false" [isFieldsDisabled]="false"></app-data-doctor>
        </div>
        
    </div>
</div>

