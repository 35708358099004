<div class="wrapper">
  <div class="maxwidth">

    <h4 class="title tipo-second-color bold">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE' | translate }}</h4>
    <div class="buttons" *ngIf="!isAdmin">

      <!-- Aparece si el usuario es médico o administrativo que abre ci's -->
      <button
        class="button tipo-white-color back-blue-color"
        [routerLink]="['generate-consent']"
        *appHasRole="GENERATE_CIS">
        <fa-icon [icon]="faFileCirclePlus" class="icono iconono"></fa-icon>
        {{ 'PRIVATE.CONSENTS.BUTTONS-CONSENT.BUTTON-NEW-CONSENT' | translate }}
      </button>
      <button class="button tipo-white-color back-blue-color" [routerLink]="['generate-gdpr']" *ngIf="isBiometric">
        <fa-icon [icon]="faFileContract" class="icono iconono"></fa-icon>
        {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-GDPR' | translate }}
      </button>

      <button class="button tipo-white-color back-blue-color" (click)="confirm()">
        <fa-icon [icon]="faTrashCan" class="icono"></fa-icon>
        {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-DELETE-PATIENT' | translate }}
      </button>
    </div>
    <div class="header_patient inlineflex back-white-color" >
      <fa-icon [icon]="faHospitalUser" class="icono tipo-second-color fa-xl"></fa-icon>
      <h4 class="title tipo-second-color">{{patientFullName}} </h4>
    </div>

    <div class="tabset">

      <!-- Tab 1 -->
      <input type="radio" name="tabset" id="tab1" aria-controls="file" [checked]="selectedTab === PatientParamsRouteEnum.FILE" (change)="changeTab(PatientParamsRouteEnum.FILE)" />
      <label for="tab1" class="bold tipo-main-color">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB1' | translate }}</label>
      <!-- Tab 2 -->
      <input type="radio" name="tabset" id="tab2" aria-controls="consents" [checked]="selectedTab === PatientParamsRouteEnum.CONSENTS" (change)="changeTab(PatientParamsRouteEnum.CONSENTS)" *appHasRole="VIEW_CIS"/>
      <label for="tab2" class="bold tipo-main-color" *appHasRole="VIEW_CIS">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB2' | translate }}</label>
      <!-- Tab 3 -->
      <input type="radio" name="tabset" id="tab3" aria-controls="gdpr" [checked]="selectedTab === PatientParamsRouteEnum.GDPR" (change)="changeTab(PatientParamsRouteEnum.GDPR)" *ngIf="isBiometric"/>
      <label for="tab3" class="bold tipo-main-color" *ngIf="isBiometric"> {{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB3' | translate }}</label>
      <!-- Tab 4 -->
      <input type="radio" name="tabset" id="tab4" aria-controls="dds" [checked]="selectedTab === PatientParamsRouteEnum.DIGITAL_DOCUMENTS" (change)="changeTab(PatientParamsRouteEnum.DIGITAL_DOCUMENTS)" *ngIf="isBiometric" />
      <label for="tab4" class="bold tipo-main-color" *ngIf="isBiometric">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB4' | translate }}</label>
      <!-- Tab 6 -->
      <input type="radio" name="tabset" id="tab6" aria-controls="notes" [checked]="selectedTab === PatientParamsRouteEnum.ADDITIONAL_NOTES" (change)="changeTab(PatientParamsRouteEnum.ADDITIONAL_NOTES)" />
      <label for="tab6" class="bold tipo-main-color"> {{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB6' | translate }} </label>

      <div class="tab-panels">
        <!-- datos paciente -->
        <section id="file" class="tab-panel">
          <app-data-patient [inEdit]="true" [isFieldsDisabled]="true" [isNewGDPR]="false" (patientEvent)="handleChildPatientFullName($event)" ></app-data-patient>
        </section>
        <!-- consentimiento paciente -->
        <section id="consents" class="tab-panel" *appHasRole="VIEW_CIS">
          <app-documents-patient [isOpenFromPatientFile]="true"></app-documents-patient>
        </section>
        <!-- permisos RGPD -->
        <section id="gdpr" class="tab-panel">
          <app-rgpd-leaves [isOpenFromPatientFile]="true" [showRGPDspermission]="showRGPDspermission"></app-rgpd-leaves>
        </section>
        <!-- documentos a medida -->
        <section id="dds" class="tab-panel" >
          <app-digital-documents [isOpenFromPatientFile]="true" [showDocumentsTypes]="showDocumentsTypes"></app-digital-documents>
        </section>
        <!-- notas adicionales -->
        <section id="notes" class="tab-panel">
          <app-additional-notes></app-additional-notes>
        </section>
      </div>
    </div>

  </div>
  <div class="window nodisplay">
    <app-window-error></app-window-error>
  </div>

  <div class="confirmar" *ngIf="confirmDelete">
    <h4 class="title tipo-second-color bold">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-CONFIRM' | translate }}</h4>
    <p class="small">{{ 'PRIVATE.PATIENT.FILE-PATIENT.SUBTITLE-CONFIRM' | translate }}</p>
    <div class="buttons">
      <button class="button tipo-white-color back-blue-color" (click)="deletePatient()">
        <fa-icon [icon]="faCheckDouble" class="icono iconono"></fa-icon>
        {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-CONFIRM' | translate }}
      </button>
      <button (click)="confirm()" class="button tipo-main-color back-grey-color">
        {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
