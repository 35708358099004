import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faPenToSquare, faFileExport, faEye, faEyeSlash, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '@app/core/services/user/user.service';
import { State } from '@app/core/models/input/state.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { ActivatedRoute } from '@angular/router';
import { Company, CompanyOutput, CompanyUserOutput } from '@app/core/models/input/company/company.model';
import { ChangePassword } from '@app/core/models/input/change-pass.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ElciValidators } from '@app/core/utils/validators';
import { LoginService } from '@app/core/services/login/login.service';
import { User } from '@app/core/models/input/user.model';
import RoleEnum from '@app/core/enums/role.enum';
import { RoleService } from '@app/core/services/role/role.service';
import { FREEMIUM_ROLES, USERS_ADMIN_ELCI } from '@app/core/constants/Permissions';
import { StateService } from '@app/core/services/state/state.service';
import { Status } from '@app/core/models/input/common/status.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import LanguageEnum from '@app/core/enums/language.enum';



@Component({
  selector: 'app-data-company',
  templateUrl: './data-company.component.html',
  styleUrls: ['./data-company.component.scss']
})
export class DataCompanyComponent implements OnInit {
  faPenToSquare = faPenToSquare;
  faFileExport = faFileExport;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircleXmark = faCircleXmark;

  // Inputs
  @Input() inEdit?: boolean;
  @Input() isFieldsDisabled = false;
  @Input() his?: string;

  dataCompany!: FormGroup;
  dataPass!: FormGroup;
  selectValue = null;
  selectValueFis = null;
  selectedStatus = null;
  inEdition = true;
  isSubmit = false;
  isSudo = false;
  company?: Company;
  user?: User;
  states?: State[];
  id?: string;
  creationDate!: string | undefined;
  isActive?: boolean;
  lastAccessDate!: string | undefined;
  cancelDate!:string | undefined;
  userUuid?: string;
  classInput = '';
  isOpenWindow = false;
  showPasswordOld = false;
  showPasswordNew = false;
  isFreemium = false;
  statuses?: Status[];
  specialty?: Specialty[] = [];
  specialties?: Specialty[];
 

  //enum
  roleEnum = RoleEnum;

  // roles
  FREEMIUM_ROLES = FREEMIUM_ROLES;
  USERS_ADMIN_ELCI = USERS_ADMIN_ELCI;

  
 

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private translate: TranslateService,
    private activedRoute: ActivatedRoute,   
    private loginService: LoginService,
    public roleService: RoleService,
    private stateService: StateService,
    private specialtiesService: SpecialtiesService
 
  ) {
    this.activedRoute.params.subscribe(parametros => {
      this.id = parametros['companyUuid'];
    });
   
  }


  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataCompany.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();

 
    // Validamos e inicializamos formulario
    this.dataCompany = new FormGroup({
      businessName: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      cif: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      address: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      locality: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      postCode: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^\d{5}$/),
      ]),
      state: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      phone: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^\d{9}$/),
      ]),
      mobile: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^\d{9}$/),
      ]),
      email: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
        ElciValidators.emailValidator
      ]),
      emailLOPD: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
        ElciValidators.emailValidator
      ]),
      username: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),

      pass: new FormControl({}, [ ]),
      status: new FormControl({}, [ Validators.required ]),
      specialities:new FormControl(null, [ ]),
      businessNameFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      cifFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      addressFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      localityFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      postCodeFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.pattern(/^\d{5,6}$/),
      ]),
      stateFis: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
    });

    this.toggleForm();

    this.dataPass = new FormGroup({
      oldPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      newPassword: new FormControl({ value: '', disabled: false }, [
        Validators.required
      ])
    })

    this.getUserStatus();
    // consultamos las especialidades
    this.specialtiesService.getSpecialties().subscribe({
      next: specialties => {
        this.specialties = specialties;
      },
    });

    if (this.id !== undefined) {
      this.companyService.getCompany(this.id).subscribe({
        next: data => {
             // para quitar del listado de todas las especialidades la especialidad que se añada a la selección
             this.specialty = data.user?.specialty;
             this.specialty?.forEach(specialty => {
               this.specialties = this.specialties?.filter(
                 specialtyFilter => specialtyFilter.id !== specialty.id
               );
             });

        
            // validamos si la empresa tiene HIS y si  el array de especialidades tiene alguna especialidad
          
            if (this.his != null && this.specialty != undefined && this.specialty?.length === 0){   
              this.dataCompany.get('specialities')?.setValidators([Validators.required ]);                
             }else {
              this.dataCompany.get('specialities')?.clearValidators();
              this.dataCompany.get('specialities')?.setValue(null);
            }
            this.dataCompany.get('specialities')?.updateValueAndValidity();

            if (data.state?.country?.id === LanguageEnum.es_EC){
              this.dataCompany.get('phone')?.setValidators([Validators.pattern(/^[0-9+]{9,15}$/)]);
              this.dataCompany.get('mobile')?.setValidators([Validators.pattern(/^[0-9+]{9,15}$/)]);
              this.dataCompany.get('postCode')?.setValidators([Validators.pattern(/^\d{6}$/)]);  
            }
            this.dataCompany.get('phone')?.updateValueAndValidity();
            this.dataCompany.get('mobile')?.updateValueAndValidity();
            this.dataCompany.get('postCode')?.updateValueAndValidity();
            
            
          this.dataCompany.controls['businessName'].setValue(data.businessName);
          this.dataCompany.controls['cif'].setValue(data.cif);
          this.dataCompany.controls['address'].setValue(data.user?.address);
          this.dataCompany.controls['locality'].setValue(data.user?.locality);
          this.dataCompany.controls['postCode'].setValue(data.user?.postCode);
          this.dataCompany.controls['state'].setValue(data.user?.state?.id);
          this.dataCompany.controls['phone'].setValue(data.user?.phone);
          this.dataCompany.controls['mobile'].setValue(data.user?.mobile);
          this.dataCompany.controls['email'].setValue(data.user?.email);
          this.dataCompany.controls['username'].setValue(data.user?.username);
          this.dataCompany.controls['pass'].setValue('******');
          this.dataCompany.controls['status'].setValue(data.user?.userStatus?.id);
          
          this.dataCompany.controls['businessNameFis'].setValue(data.businessNameFis);
          this.dataCompany.controls['cifFis'].setValue(data.cifFis);
          this.dataCompany.controls['businessNameFis'].setValue(data.businessNameFis);
          this.dataCompany.controls['addressFis'].setValue(data.address);
          this.dataCompany.controls['localityFis'].setValue(data.locality);
          this.dataCompany.controls['postCodeFis'].setValue(data.postCode);
          this.dataCompany.controls['stateFis'].setValue(data.state?.id);
          this.dataCompany.controls['emailLOPD'].setValue(data.emailLOPD);
          this.creationDate = data.creationDate?.toLocaleString();
             
          this.isActive = data.active;
          this.userUuid = data.user?.uuid;
          this.lastAccessDate = data.user?.lastAccessDate?.toLocaleString();
          this.cancelDate = data.user?.cancelDate?.toLocaleString();
         
          // consultamos las provincias asignadas al país
          this.stateService.getStateByCountryId(data.state?.country?.id != undefined ? data.state?.country?.id : 0).subscribe({
            next: states => {
              this.states = states;
            }
          })
        }
      })
    }

  }

  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled;
    this.toggleForm();
    this.dataCompany.controls['pass'].disable();
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataCompany.disable();
      this.inEdition = false;
    } else {
      this.dataCompany.enable();
      this.inEdition = true;
    }
  }

  openWindow(): void {
    this.isOpenWindow = !this.isOpenWindow;
  }



  changePass() {
    const changePass: ChangePassword = {
      oldPassword: this.dataPass.get('oldPassword')?.value,
      newPassword: this.dataPass.get('newPassword')?.value,
      newRePassword: this.dataPass.get('newPassword')?.value
    }
    if (this.userUuid != undefined) {
      this.userService.changePassword(this.userUuid, changePass).subscribe(() => {
        this.alertService.success(this.translate.instant("PRIVATE.COMPANY.DATA-COMPANY.MESSAGE-CHANGE-PASS-OK"));
      })
      this.isOpenWindow = !this.isOpenWindow;
    }
  }

  showPassOld(){
    this.showPasswordOld = !this.showPasswordOld;
  }

  showPassNew(){
    this.showPasswordNew = !this.showPasswordNew;
  }

  getUserStatus() {
    this.userService.getUserStatus().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
    });
  }
 // para quitar una especialidad
  deleteItem(i: number, specialty: Specialty) {
    this.specialty?.splice(i, 1);
    this.specialties?.push(specialty);  
   
    if (this.his != null && this.specialty?.length == 0 ){  
      this.dataCompany.get('specialities')?.setValidators([Validators.required ]); 
      this.dataCompany.get('specialities')?.setValue(null);               
     }else {
      this.dataCompany.get('specialities')?.clearValidators();
     
    }
    this.dataCompany.get('specialities')?.updateValueAndValidity();
  }
  // para añadir una especialidad
  addItem(i: number, specialtyAdd: Specialty) {
    if (this.specialty != undefined ) {
      this.specialties?.splice(i, 1);
      this.specialty?.push(specialtyAdd);
    }
    
    if (this.his != null && this.specialty?.length == 0){ 
      this.dataCompany.get('specialities')?.setValidators([Validators.required ]);  
      this.dataCompany.get('specialities')?.setValue(null);              
    }else {
     this.dataCompany.get('specialities')?.clearValidators();
   }
   this.dataCompany.get('specialities')?.updateValueAndValidity();
  }
 

  onSubmit() {

    this.isSubmit = true;

    // marca el input con error si no se rellena bien
    Object.keys(this.dataCompany.controls).forEach(controlName => {
      this.dataCompany.get(controlName)?.markAsTouched();
    });

    const controlEmailLopd = this.dataCompany.get('emailLOPD');
       if (this.roleService.hasRole(FREEMIUM_ROLES)) {
         controlEmailLopd?.clearValidators();
       }else {
        controlEmailLopd?.setValidators([Validators.required,
          Validators.minLength(3),
          ElciValidators.emailValidator]);
       }
       controlEmailLopd?.updateValueAndValidity();      

    if (this.dataCompany.valid) {

        // extraemos solo los id's del array de especialidades que contiene id's y description de cada especialidad
      const specialtyIds: number[] =
      this.specialty?.map(specialty => specialty?.id ?? 0) ?? []; 

      const userCompany: CompanyUserOutput = {
        uuid: this.userUuid,
        username: this.dataCompany.get('username')?.value,
        email: this.dataCompany.get('email')?.value,
        phone: this.dataCompany.get('phone')?.value,
        mobile: this.dataCompany.get('mobile')?.value,
        address: this.dataCompany.get('address')?.value,
        locality: this.dataCompany.get('locality')?.value,
        postCode: this.dataCompany.get('postCode')?.value,
        stateId: this.dataCompany.get('state')?.value,
        userStatusId: this.dataCompany.get('status')?.value,
        specialtiesIds: specialtyIds,
        password:
        this.userUuid !== undefined
          ? undefined
          : this.dataCompany.get('pass')?.value,
      }

      const companyObject: CompanyOutput = {
        uuid: this.id,
        businessName: this.dataCompany.get('businessName')?.value,
        cif: this.dataCompany.get('cif')?.value,
        address: this.dataCompany.get('addressFis')?.value,
        locality: this.dataCompany.get('localityFis')?.value,
        postCode: this.dataCompany.get('postCodeFis')?.value,
        businessNameFis: this.dataCompany.get('businessNameFis')?.value,
        cifFis: this.dataCompany.get('cifFis')?.value,
        emailLOPD: this.dataCompany.get('emailLOPD')?.value,
        stateId: this.dataCompany.get('stateFis')?.value,
        user: userCompany
      }

      this.companyService.editCompany(companyObject).subscribe(() => {
        this.alertService.success(this.translate.instant("PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-MODIFY-ADMINISTRATOR"));
        this.changeEdit();
      })
      
    }
    else {
      this.classInput = 'wrong';
      this.alertService.error(this.translate.instant("PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.MESSAGE-ERROR"));
    }

  }

}
